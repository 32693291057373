import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Rest from "../utils/rest";
import Table from "./common/Table";
import OrgService from "../service/OrgService";

export default class Orgs extends Component {
  state = { rows: [], nofamilies: [] };

  async componentDidMount() {
    await this.loadOrgs();
  }

  loadOrgs = async () => {
    let state = { rows: [], nofamilies: [] };
    let response = await Rest.get("/api/orgs");
    if (response.status === 200) {
      let data = [...response.data];
      data.sort((a, b) => (a.name < b.name ? -1 : a.name === b.name ? 0 : 1));
      state.rows = data;
    }
    this.setState(state);
  };

  render() {
    return (
      <React.Fragment>
        <Table
          schema={OrgService.getSchema()}
          rows={this.state.rows}
          history={this.props.history}
        />
      </React.Fragment>
    );
  }
}
