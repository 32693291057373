import Service from "./Service";

let schema = {
  baseUrl: `${process.env.PUBLIC_URL}/orgs`,
  viewTitle: "Orgs",
  editTitle: "Edit Org",
  columns: ["shortname", "name"],
  fields: {
    shortname: {
      label: "Short Name",
      link: true,
      display: "d-none d-sm-table-cell",
    },
    name: {
      label: "Full Name",
      display: "d-none d-sm-table-cell",
    },
  },
};

export default class OrgService extends Service {
  static getSchema() {
    return schema;
  }
  static getField(fieldName) {
    return schema.fields[fieldName];
  }
}
