import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Rest from "../utils/rest";
import Field from "./common/Field";
import Form from "./common/Form";
import OrgService from "../service/OrgService";

export default class Org extends Component {
  state = {
    editMode: this.props.match.params.id === "new",
    org: {
      name: "",
      shortname: "",
    },
  };

  async componentDidMount() {
    let state = {};
    if (this.props.match.params.id !== "new") {
      let response = await Rest.get("/api/orgs/" + this.props.match.params.id);
      state.org = response.data;
    }
    this.setState(state);
  }

  handleChange = ({ currentTarget: input }) => {
    let org = { ...this.state.org };
    org[input.name] = input.value;
    this.setState({ org });
  };

  renderContent = () => {
    return (
      <div>
        <React.Fragment>
          <Field
            readonly={!this.state.editMode}
            label="Full Name"
            name="name"
            type="text"
            value={this.state.org.name}
            onChange={this.handleChange}
          />
          <Field
            readonly={!this.state.editMode}
            label="Short Name"
            name={"shortname"}
            key={"shortname"}
            type="text"
            value={this.state.org.shortname}
            onChange={this.handleChange}
          />
        </React.Fragment>
      </div>
    );
  };

  renderButtons = () => {
    if (!this.state.editMode) {
      return (
        <React.Fragment>
          <button
            className="btn btn-secondary mr-2"
            onClick={(e) => {
              this.setState({ editMode: true });
            }}
          >
            Edit
          </button>
          <button
            className="btn btn-secondary mr-2"
            onClick={(e) => {
              this.props.history.push(`${process.env.PUBLIC_URL}/orgs`);
            }}
          >
            Close
          </button>
        </React.Fragment>
      );
    }

    let org = {};
    let fields = OrgService.getSchema().fields;
    Object.keys(fields).forEach((fieldName) => {
      let field = fields[fieldName];
      if (field.type !== "calculated") {
        org[fieldName] = this.state.org[fieldName];
      }
    });

    return (
      <Form
        history={this.props.history}
        id={this.props.match.params.id}
        form="orgs"
        data={org}
      />
    );
  };

  render() {
    if (!Rest.getUserUnid()) {
      this.props.history.push(`${process.env.PUBLIC_URL}/`);
      return null;
    }
    return (
      <React.Fragment>
        {this.renderContent()}
        {this.renderButtons()}
      </React.Fragment>
    );
  }
}
